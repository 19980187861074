import React, { Component } from "react";
import { Card, Row, Col } from "antd";
import Header from "./Header.js";
export default class Home extends Component {
  render() {
    return (
      <section>
        <Card className="banner canine-body">
          <Header />
          <Col span={24}>
            <Row gutter={[32, 32]}>
              <Col span={12}>
                <Card
                  style={{
                    //  background-position:center;
                    backgroundColor: "#60b3aa",
                    backgroundSize: "cover",
                    opacity: "0.9",
                    height: "89%",
                    color: "white",
                    // padding: "5%",
                    width: "450px",
                    marginTop: "100px",
                    marginLeft: "100px",
                  }}
                >
                  <h1 className="bannerheading">
                    <span className="bannerheading1">For Veterinarians</span>
                  </h1>

                  <p className="bannertext">
                    Canine Lab Testing is veterinarian owned and operated. Our
                    mission is to improve the health of the doggy day care
                    population. We do this this through parasite screening,
                    optimizing vaccine protocols, and reviewing sanitation and
                    cleaning protocols. Group play facilities have exploded in
                    that last 2 years and more dogs are in close contact than
                    they ever before. Unfortunately fecal testing compliance by
                    owners has not increased. According to CAPC, in the
                    Northeast Giardia prevalence is 1:12 and Hookworms are 1:50.
                    Many group play facilities have hundreds of dogs in their
                    program! And parasites can spread quickly! Chances are, if
                    you have a facility in your area using Canine Lab Testing
                    you will see an increase of positive results coming from
                    your clients. Many of these are dogs that were not getting
                    tested at all!
                  </p>
                </Card>
              </Col>
              <Col span={12}>
                <Card
                  style={{
                    //  background-position:center;
                    backgroundColor: "#60b3aa",
                    backgroundSize: "cover",
                    opacity: "0.9",
                    height: "80%",
                    color: "white",
                    // padding: "5%",
                    width: "450px",
                    marginTop: "100px",
                    marginLeft: "140px",
                  }}
                >
                  <div>
                    <p className="bannertext">
                      Veterinarians at CLT are available to discuss any cases or
                      questions you have. We do not treat or develop a
                      Veterinarian-Client-Patient relationship, and we do not
                      prescribe. Screening for parasites if only a part of what
                      we do. If a pet is positive for a parasite, pet parents
                      are instructed to bring the result to their primary care
                      veterinarian for appropriate treatment. Which may include
                      prescription medications, an exam, and a recheck fecal
                      sample. Pet parents always have the option of bringing a
                      sample into their veterinarian or having it collected at
                      the facility they attend. For some pet parents getting a
                      sample to their veterinarian is inconvenient; due to work
                      schedules or the location of their Veterinary Clinic. If
                      parasite screening is delayed it is the pet, pet parent,
                      and pet care facility that suffer.
                    </p>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
        </Card>
      </section>
    );
  }
}
