import React, { Component } from "react";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Home from "./Home.js";
import Parents from "./parents";
import Contactus from "./Contactus.js";
import Veterinarians from "./Veterinarians";
import Facilities from "./Facilities.js";

export default class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route path="/contactUs" component={Contactus} />
            <Route exact path="/" component={Home} />
            <Route path="/parents" component={Parents} />
            <Route path="/veterinarians" component={Veterinarians} />
            <Route path="/facilities" component={Facilities} />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}
