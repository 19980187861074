import React, { Component } from "react";
import { Card, Row, Col } from "antd";
import Header from "./Header.js";
import icon2 from "./images/L000.png";
import icon3 from "./images/L0001.png";
export default class Home extends Component {
  render() {
    return (
      <section>
        <Card className="banner canine-body">
          <div>
            <Header />
            <Card
              style={{
                //  background-position:center;
                backgroundColor: "#60b3aa",
                backgroundSize: "cover",
                opacity: "0.9",
                height: "80%",
                color: "white",
                padding: "5%",
                width: "450px",
                marginTop: "100px",
              }}
            >
              <h1 className="bannerheading">
                <span className="bannerheading1">For Pet Facilities</span>
              </h1>

              <p className="bannertext">
                Canine Lab Testing is a Veterinarian owned and operated
                consulting service providing pet health and well being services
                to doggy day care, boarding facilities, and pet care centers.
                There are so many new doggy day care facilities providing much
                needed enrichment for our pet population and keeping these
                groups of dogs healthy and safe can present some unique
                challenges. Like so many things, it often takes a team to get
                the best results. We are proud to partner with these facilities
                who only want the best for the pets in their care!
              </p>
            </Card>
            ;
          </div>
        </Card>

        <Card style={{ backgroundColor: "#bd9180" }}>
          <Col span={24}>
            <Row>
              <Col span={6}>
                <Card style={{ backgroundColor: "#bd9180", border: "0px" }}>
                  <div>
                    <div className="iconbackground text-center">
                      {/* <img src={icon1} className="icon"></img> */}
                    </div>

                    <h3 className="fullcontrol">FULL CONTROL</h3>

                    <h5 style={{ color: "white" }}>
                      {" "}
                      ________________________________________________
                    </h5>
                    <p className="fullcontroltext">
                      Pet Parents will appreciate the convenience and the
                      assurance of a clean and safe play space for their pet!
                    </p>
                  </div>
                </Card>
              </Col>
              <Col span={6}>
                <Card style={{ backgroundColor: "#bd9180", border: "0px" }}>
                  <div>
                    <div className="iconbackground text-center">
                      <img src={icon2} className="icon"></img>
                    </div>
                    <h3 className="fullcontrol">LOW COST</h3>
                    <h5 style={{ color: "white" }}>
                      {" "}
                      ________________________________________________
                    </h5>
                    <p className="fullcontroltext">
                      Pet Care Centers and Doggy Day Care facilities who are
                      monitoring for parasite are provided an online portal and
                      results are available the next day!
                    </p>
                  </div>
                </Card>
              </Col>
              <Col span={6}>
                <Card style={{ backgroundColor: "#bd9180", border: "0px" }}>
                  {" "}
                  <div>
                    <div className="iconbackground text-center">
                      <img src={icon3} className="icon"></img>
                    </div>
                    <h3 className="fullcontrol">CERTIFIED TESTING</h3>
                    <h5 style={{ color: "white" }}>
                      ________________________________________________
                    </h5>
                    <p className="fullcontroltext">
                      Nationally Certified Veterinary Laboratory Results you can
                      trust!
                    </p>
                  </div>
                </Card>
              </Col>
              <Col span={6}>
                <Card style={{ backgroundColor: "#bd9180", border: "0px" }}>
                  {" "}
                  <div>
                    <div className="iconbackground text-center">
                      {/* <img src={icon3} className="icon"></img> */}
                    </div>
                    <h3 className="fullcontrol">CERTIFIED TESTING</h3>
                    <h5 style={{ color: "white" }}>
                      ________________________________________________
                    </h5>
                    <p className="fullcontroltext">
                      CLT can review vaccine and cleaning protocols to provide
                      the safest environment for group play!
                    </p>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
        </Card>
      </section>

      // <div>
      //   {" "}
      //   <Header />
      //   <section className="banner canine-body">
      //     <div className="row">
      //       <div className="col-lg-5 col-sm-8 col-xs-8 column">
      //         <div className="bannerContent">
      //           <h1 className="bannerheading">
      //             TAKE CONTROL OF{" "}
      //             <span className="bannerheading1">FECAL TESTING</span>
      //           </h1>
      //           <h5 className="line">____________</h5>
      //           <p className="bannertext">
      //             Canine Lab Testing is a Veterinarian owned and operated
      //             consulting service providing pet health and well being
      //             services to doggy day care, boarding facilities, and pet care
      //             centers. There are so many new doggy day care facilities
      //             providing much needed enrichment for our pet population and
      //             keeping these groups of dogs healthy and safe can present some
      //             unique challenges. Like so many things, it often takes a team
      //             to get the best results. We are proud to partner with these
      //             facilities who only want the best for the pets in their care!
      //           </p>
      //         </div>
      //       </div>
      //     </div>
      //   </section>
      //   {/* <section className = "canineuse canine-body">
      //       <div className = "row">
      //         <div className = "col-lg-6 text-center">
      //           <img src = {canine} className = "canine-img"/>
      //         </div>
      //         <div className = "col-lg-6">
      //           <h1 className ="useheading1">WHY USE</h1>
      //             <h1 className ="useheading">CANINE LAB TESTING?</h1>
      //           <p className = "usetext"><span>Canine Lab Testing</span> provides lab testing services for canine boarding and doggy daycare facilities. Fecal parasites are a headache for every boarding facility. Parasites, like giardia, are spread by direct fecal transmission. That means when Fido comes into your facility with giardia, every dog that says "hello" to Fido by sniffing her rear end will be at risk of going home that day with giardia. When you have cases of giardia diagnosed by a local veterinarain, Who is blamed and asked to pay the vet bill? You!
      //           </p>
      //           <p className = "usetext">
      //           Now you can take control of fecal testing and easily set up your own parasite monitoring program. Detect parasites before they spread around your dog population. You will no longer have to rely on local veterinarians to run tests and send over results. You simply log into your account, enter the pets information, and collect a fecal sample. A courier will come to your facility that evening to pickup and transport your samples to the lab. Within 24 hours the result will be available in your online portal to view and print directly from your office. Testing is performed by a national GLP certified laboratory, not by a veterinarian with a microscope.
      //           </p>

      //         </div>
      //       </div>
      //       <Container className ="text-center">

      //       <h1 className = "betterheading">BETTER FOR YOU, BETTER FOR YOUR CUSTOMERS</h1>
      //       <p className ="bettertext" >You will no longer have to send clients to their veterinarian just to drop off a fecal sample. Or worse, be forced to accept a dog who is overdue on his or her fecal sample and possibly exposing other dogs to parasites. We have heard it all..."My dog goes in the woods, I can't find it!" "My kids walk the dog and they never pick up the poop!" "My vet is all the way on the other side of town, I just can't get there before they close!" Clients will love the convenience and appreciate your diligence in keeping your pack parasite free.
      //       </p>

      //       </Container>

      //     </section> */}
      //   <section className="canine-lab canine-body">
      //     {/* <div className="row titlebody">
      //       {/* <div className="col-lg-8   "></div> */}
      //       {/* <div className = "col-lg-4 col-md-12 col-sm-12">
      //           <div className ="label">
      //           <div className ="title">
      //             <h1 className ="title1"><span><img src = {shape} className ="shape"/></span>FULL CONTROL</h1>
      //           </div>
      //           </div>
      //           <div className ="label">
      //           <div className ="title">
      //             <h1 className ="title1"><span><img src = {shape} className ="shape"/></span>LOW COST</h1>
      //           </div>
      //           </div>
      //           <div className ="label">
      //           <div className ="title">
      //             <h1 className ="title1"><span><img src = {shape} className ="shape"/></span>CERTIFIED TESTING</h1>
      //           </div>
      //           </div>
      //         </div> */}
      //     {/* </div> */}
      //     <div className="row full ">
      //       <div className="col-lg-4 text-center labtest ">
      //         <div className="iconbackground text-center">
      //           <img src={icon1} className="icon"></img>
      //         </div>

      //         <h3 className="fullcontrol">FULL CONTROL</h3>

      //         <h5 style={{ color: "white" }}> _______</h5>
      //         <p className="fullcontroltext">
      //           Setting up an account is free and easy. You only pay for the
      //           samples you submit. You decide on the frequency and which type
      //           of fecal test. If your veterinarian uses a GLP certified lab,
      //           this result will be of the sample quality. The difference is you
      //           will be in control of submitting the test and receiving the
      //           results!
      //         </p>
      //       </div>

      //       <div className="col-lg-4 text-center labtest">
      //         <div className="iconbackground text-center">
      //           <img src={icon2} className="icon"></img>
      //         </div>
      //         <h3 className="fullcontrol">LOW COST</h3>
      //         <h5 style={{ color: "white" }}> _______</h5>
      //         <p className="fullcontroltext">
      //           We provide these tests for a wholesale price, typically below
      //           veterinarian prices. We recommend that you mark up the price to
      //           compensate your costs of collecting the sample.
      //         </p>
      //       </div>
      //       <div className="col-lg-4 text-center ">
      //         <div className="iconbackground text-center">
      //           <img src={icon3} className="icon"></img>
      //         </div>
      //         <h3 className="fullcontrol">CERTIFIED TESTING</h3>
      //         <h5 style={{ color: "white" }}>_______</h5>
      //         <p className="fullcontroltext">
      //           Testing is performed by a National GLP Certified Laboratory and
      //           is in accordance with the Companion Animal Parasite Council
      //           (CAPC) recommended methodology for routine fecal testing.
      //         </p>
      //       </div>
      //     </div>
      //   </section>
      //   <section className="additional canine-body">
      //     <div className="row  ">
      //       <div className="col-lg-4 text-center labtest ">
      //         <div className="iconbackground text-center">
      //           <img src={icon1} className="icon"></img>
      //         </div>

      //         <h3 className="fullcontrol">FULL CONTROL</h3>

      //         <h5 style={{ color: "white" }}> _______</h5>
      //         <p className="fullcontroltext">
      //           Setting up an account is free and easy. You only pay for the
      //           samples you submit. You decide on the frequency and which type
      //           of fecal test. If your veterinarian uses a GLP certified lab,
      //           this result will be of the sample quality. The difference is you
      //           will be in control of submitting the test and receiving the
      //           results!
      //         </p>
      //       </div>

      //       <div className="col-lg-4 text-center labtest">
      //         <div className="iconbackground text-center">
      //           <img src={icon2} className="icon"></img>
      //         </div>
      //         <h3 className="fullcontrol">LOW COST</h3>
      //         <h5 style={{ color: "white" }}> _______</h5>
      //         <p className="fullcontroltext">
      //           We provide these tests for a wholesale price, typically below
      //           veterinarian prices. We recommend that you mark up the price to
      //           compensate your costs of collecting the sample.
      //         </p>
      //       </div>
      //       <div className="col-lg-4 text-center labtest ">
      //         <div className="iconbackground text-center">
      //           <img src={icon3} className="icon"></img>
      //         </div>
      //         <h3 className="fullcontrol">CERTIFIED TESTING</h3>
      //         <h5 style={{ color: "white" }}>_______</h5>
      //         <p className="fullcontroltext">
      //           Testing is performed by a National GLP Certified Laboratory and
      //           is in accordance with the Companion Animal Parasite Council
      //           (CAPC) recommended methodology for routine fecal testing.
      //         </p>
      //       </div>
      //     </div>
      //   </section>
      // </div>
    );
  }
}
