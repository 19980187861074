import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import image from "./images/img.png";
import { Link } from "react-router-dom";
export default class Header extends Component {
  state = {
    backgroundColor: "transparent",
    marginTop: "2.5%",
  };
  listenScrollEvent = (e) => {
    if (window.scrollY > 50) {
      this.setState({ backgroundColor: "black", marginTop: "0%" });
    } else {
      this.setState({ backgroundColor: "transparent" });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent, true);
  }
  render() {
    return (
      <div>
        <Navbar
          // collapseOnSelect
          // expand="lg"
          className="navbar fixed-top"
          style={{
            backgroundColor: this.state.backgroundColor,
            marginTop:"0px"
            
          }}
        >
          <Navbar.Brand as={Link} to="/">
            <div>
              {/* <span style={{ fontSize: "40px", color: "#4672c4" }}>C</span>
              <span>ANINE</span>{" "}
              <span className="logo">
                <span style={{ fontSize: "40px", color: "#4672c4" }}>L</span>{" "}
                <span>AB</span>
                <span style={{ fontSize: "40px", color: "#4672c4" }}>
                  T
                </span>{" "}
                <span>ESTING</span>
              </span> */}
              <span style={{ fontSize: "40px" }}>CANINE LAB TESTING</span>
            </div>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            className="justify-content-end "
            id="basic-navbar-nav"
          >
            <Nav>
             
              <Nav.Link as={Link} to="/">
                For Pet Facilities
              </Nav.Link>
              <Nav.Link as={Link} to="/parents">
                {" "}
                For Pet Parents
              </Nav.Link>

              <Nav.Link as={Link} to="/veterinarians">
                For Veterinarians
              </Nav.Link>
              <Nav.Link as={Link} to="/contactUs">
                Contact Us
              </Nav.Link>
              <Nav.Link href="http://app.dev.caninelabtesting.com/">
                <span>
                  <img src={image} style={{ width: "20px", height: "20px" }} alt=""/>
                </span>
                LOGIN
              </Nav.Link>
              {/* <Nav.Link href="https://app.caninelabtesting.com/"><span><img src={image} style={{width:'20px',height:'20px'}}/></span>LOGIN</Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}
