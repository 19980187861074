import React, { Component } from "react";
import Header from "./Header.js";
import { Card, Row, Col } from "antd";
import icon2 from "./images/L000.png";
import icon3 from "./images/L0001.png";
export default class Home extends Component {
  render() {
    return (
      <section>
        <Card className="banner canine-body">
          <div>
            <Header />
            <Card
              style={{
                //  background-position:center;
                backgroundColor: "#60b3aa",
                backgroundSize: "cover",
                opacity: "0.9",
                height: "80%",
                color: "white",
                // padding: "5%",
                width: "531px",
                marginTop: "100px",
              }}
            >
              <h1 className="bannerheading">
                <span className="bannerheading1">For Pet Parents</span>
              </h1>

              <p className="bannertext">
                There are many aspects to keeping your dog happy and healthy.
                Annual visits to your veterinarian is the cornerstone of your
                pet’s health. And for many dogs the enrichment and interaction
                provided by day care and pet care centers are crucial to their
                mental health and have been shown to reduce unwanted behaviors
                at home. During your dog’s annual visit please discuss with your
                veterinarian that your pet plays in a group setting with other
                dogs and take their recommendations for vaccines, health
                screens, and preventive medications. Its not just about keeping
                your dog healthy, it about keeping all of your dog’s friends
                healthy too! Your daycare or pet care center may have chosen to
                partner with Canine Lab Testing. We are a Veterinarian owned and
                operated consulting service and work exclusively with daycare
                facilities. WE DO NOT REPLACE YOUR VETERINARIAN, we only assist
                your daycare in making sure the environment your dog plays in is
                safe! Giving you added confidence in those who are caring for
                your dog!
              </p>
            </Card>
          </div>
        </Card>

      
      </section>
    );
  }
}
