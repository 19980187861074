import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Card } from "antd";
import Header from "./Header.js";

import { Modal } from "react-bootstrap";
import shape from "./images/Shape.png";
export default class Contactus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstname: "",
      lastname: "",
      facility: "",
      email: "",
      phonenumber: "",
      message: "",
      city: "",
      state: "",
      popMsg: "",
      popup: false,
    };
  }
  buttonvalue = () => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var nameformat = /^[a-zA-Z]+$/;
    var numberformat =
      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    let valid = true;
    let errorMsg1 = "";
    let errorMsg2 = "";
    let errorMsg3 = "";
    let errorMsg4 = "";
    if (
      !this.state.firstname.match(nameformat) ||
      this.state.firstname === ""
    ) {
      errorMsg1 = "Please enter First Name \n ";
      valid = false;
    }
    if (!this.state.email.match(mailformat) || this.state.email === "") {
      errorMsg2 = "Please enter Valid Email \n ";
      valid = false;
    }
    if (
      !this.state.phonenumber.match(numberformat) ||
      this.state.phonenumber === ""
    ) {
      errorMsg3 = "Please Enter Valid Phone Number \n ";
      valid = false;
    }
    if (this.state.state === "") {
      errorMsg4 = "Please select State \n";
      valid = false;
    }
    if (!valid) {
      this.setState({
        popMsg: (
          <React.Fragment>
            <div>{errorMsg1}</div>
            <div>{errorMsg2}</div>
            <div>{errorMsg3}</div>
            <div>{errorMsg4}</div>
          </React.Fragment>
        ),
        popup: true,
      });
      // this.toggle();
    }

    console.log(this.state);
    return valid;
  };

  firstnameValues = (event) => {
    this.setState({
      firstname: event.target.value,
    });
  };
  lastnameValues = (event) => {
    this.setState({
      lastname: event.target.value,
    });
  };
  facilityValues = (event) => {
    this.setState({
      facility: event.target.value,
    });
  };
  emailValues = (event) => {
    this.setState({
      email: event.target.value,
    });
  };
  phonenumberValues = (event) => {
    this.setState({
      phonenumber: event.target.value,
    });
  };
  messageValues = (event) => {
    this.setState({
      message: event.target.value,
    });
  };
  cityValues = (event) => {
    this.setState({
      city: event.target.value,
    });
  };
  stateValue = (event) => {
    this.setState({
      state: event.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.buttonvalue()) {
      // alert("Please enter correct values!");
      return;
    } else {
      this.setState({
        popMsg: "Thankyou for contacting us. Your form has been submitted",
        popup: true,
        firstname: "",
        lastname: "",
        email: "",
        phonenumber: "",
        message: "",
        facility: "",
        city: "",
        state: "",
      });
    }

    let entityData = {
      email: this.state.email,
      subject: "Website Contact (caninelabtesting.com)",
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      phonenumber: this.state.phonenumber,
      message: this.state.message,
    };
    fetch("https://api.dev.caninelabtesting.com/noAuth/sendMail", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(entityData),
    });
  };

  toggle = () => {
    this.setState({
      popup: false,
    });
  };
  render() {
    return (
      <Card className="banner canine-body">
        <Header />
        <Card style={{marginTop:"70px"}}>
          <h3 className="form-text">
            Looking for a demo or interested in using our system at your
            facility?
          </h3>
          <div className="row ">
            <div className="col-lg-5 formrow ">
              <Form className="form" onSubmit={this.handleSubmit}>
                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    value={this.state.firstname}
                    onChange={this.firstnameValues}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    value={this.state.lastname}
                    onChange={this.lastnameValues}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Facility</Form.Label>
                  <Form.Control
                    value={this.state.facility}
                    onChange={this.facilityValues}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicPhonenumber">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    class="form-control"
                    value={this.state.phonenumber}
                    onChange={this.phonenumberValues}
                  />
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    value={this.state.email}
                    onChange={this.emailValues}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.state.state}
                    onChange={this.stateValue}
                  >
                    <option value="">Please select state</option>
                    <option>Alabama</option>
                    <option> Alaska</option>
                    <option>Arizona</option>
                    <option> Arkansas</option>
                    <option>California</option>
                    <option>Colorado</option>
                    <option>Connecticut</option>
                    <option>Delaware</option>
                    <option> Florida</option>
                    <option>Georgia</option>
                    <option>Hawaii</option>
                    <option>Idaho</option>
                    <option>Illinois</option>
                    <option> Indiana</option>
                    <option>Iowa</option>
                    <option>Kansas</option>
                    <option>Kentucky</option>
                    <option> Louisiana</option>
                    <option> Maine</option>
                    <option>Maryland</option>
                    <option>Massachusetts</option>
                    <option>Michigan</option>
                    <option> Minnesota</option>
                    <option>Mississippi</option>
                    <option>Missouri</option>
                    <option> Montana</option>
                    <option>Nebraska</option>
                    <option> Nevada</option>
                    <option>New Hampshire</option>
                    <option>New Jersey</option>
                    <option>New Mexico</option>
                    <option>New York</option>
                    <option>North Carolina</option>
                    <option>North Dakota</option>
                    <option>Ohio</option>
                    <option>Oklahoma</option>
                    <option>Oregon</option>
                    <option>Pennsylvania</option>
                    <option>Rhode Island</option>
                    <option>South Carolina</option>
                    <option>South Dakota</option>
                    <option>Tennessee</option>
                    <option> Texas</option>
                    <option>Utah</option>
                    <option>Vermont</option>
                    <option>Virginia</option>
                    <option>Washington</option>
                    <option>West Virginia</option>
                    <option>Wisconsin</option>
                    <option>Wyoming</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    value={this.state.city}
                    onChange={this.cityValues}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    value={this.state.message}
                    onChange={this.messageValues}
                    className="textarea"
                  />
                </Form.Group>

                <Button type="submit" className="submitbutton">
                  Submit
                </Button>
              </Form>
            </div>
            <div className="col-lg-7 ">
              <div className="contactimg">
                <div className="row contactrow">
                  <div className="col-lg-7 col-md-7  "></div>
                  <div className="col-lg-5 col-md-5 col-sm-12">
                    <div className="contactlabel">
                      <div className="contacttitle">
                        <h4 className="contacttitle1">
                          <span>
                            <img src={shape} className="contactshape" alt="" />
                          </span>
                          FULL CONTROL
                        </h4>
                      </div>
                    </div>
                    <div className="contactlabel">
                      <div className="contacttitle">
                        <h4 className="contacttitle1">
                          <span>
                            <img src={shape} className="contactshape" alt="" />
                          </span>
                          LOW COST
                        </h4>
                      </div>
                    </div>
                    <div className="contactlabel">
                      <div className="contacttitle">
                        <h4 className="contacttitle1">
                          <span>
                            <img src={shape} className="contactshape" alt="" />
                          </span>
                          CERTIFIED TESTING
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
        <Modal show={this.state.popup}>
          <Modal.Body closeButton>
            <div
              style={{ float: "right", cursor: "pointer" }}
              onClick={this.toggle}
            >
              <h3>X</h3>
            </div>
            <div>
              <h3
                style={{
                  padding: "10px",
                  fontSize: "20px",
                  fontFamily: "poppinslight",
                  lineHeight: "30px",
                  WhiteSpace: "pre-wrap",
                  textAlign: "center",
                }}
              >
                {" "}
                {this.state.popMsg}{" "}
              </h3>
            </div>
          </Modal.Body>
        </Modal>
      </Card>
    );
  }
}
