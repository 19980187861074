import React, { Component } from "react";
import { Card, Row, Col } from "antd";
import Header from "./Header.js";
export default class Home extends Component {
  render() {
    return (
      <section className="bannerContent2">
        <div>
          <div>
            <Col span={24}>
              <Col span={24}>
                {" "}
                <Header />
              </Col>
              <Col span={24}>
                <Row gutter={[32, 32]}>
                  <Col span={12}>
                    <Card
                      style={{
                        //  background-position:center;
                        backgroundColor: "#60b3aa",
                        backgroundSize: "cover",
                        opacity: "0.9",
                        height: "89%",
                        color: "white",
                        padding: "2%",
                        width: "450px",
                        marginTop: "60px",
                      }}
                    >
                      <h1 className="bannerheading">
                        <span className="bannerheading1">
                          For Doggy Day Care and Enrichment Facilities
                        </span>
                      </h1>

                      <p className="bannertext">
                        Canine Lab Testing is a Veterinarian owned and operated
                        consulting service. We partner exclusively with
                        facilities providing group play in the form of doggy day
                        care and pet enrichment centers. We believe in a
                        comprehensive approach to a pet’s well being that
                        involves veterinarians, pet enrichment staff, and pet
                        parents. The number of dogs in group play has exploded
                        in the last 2 years, with new facilities being built
                        everyday. With that comes the challenge of keeping these
                        groups of dogs safe and healthy. Canine Lab Testing
                        provides a number of services for such as parasite
                        monitoring, vaccine protocol review, and a review of
                        sanitation and cleaning procedures.
                      </p>
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card
                      style={{
                        //  background-position:center;
                        backgroundColor: "#60b3aa",
                        backgroundSize: "cover",
                        opacity: "0.9",
                        height: "89%",
                        color: "white",
                        padding: "2%",
                        width: "450px",
                        marginTop: "100px",
                      }}
                    >
                      <div>
                        <p className="bannertext">
                          According to Companion Animal Parasite Council, in the
                          Northeast, giardia prevalence is 1:12 and hookworms
                          are 1:50. That means if you have a 100 dogs in your
                          program 8 of them will have giardia and 2 of them will
                          have hookworms. Unfortunately, many pet owners are not
                          compliant with fecal testing. When they are not
                          compliant, your facility and the dogs in it are put at
                          risk. But there is a pretty simple solution; offer
                          fecal testing directly from your facility. Canine Lab
                          Testing will provide you with the training you need to
                          ensure the health of your dogs. Your pet parents will
                          love the convenience, and your facility will be
                          healthier for it!
                        </p>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Col>
            <div></div>
            <div className="col-lg-1 col-sm-8 col-xs-8 column"></div>
          </div>
        </div>
      </section>
    );
  }
}
